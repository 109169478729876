import * as React from "react";
import styled from "styled-components";
import PageWrapper from "../components/shared/PageWrapper";
import ContentDiv from "../components/shared/ContentDiv";
import {
  LocalStorageInterface as LSI,
  ScreenSizes,
  configureLogoutTimer,
  createLogOutTimer,
  isSmallScreen,
  parseActiveAccount,
} from "../shared-code";
import backgroundImage from "../images/web-bg.jpg";
import { AccountInfo } from "../components/DataModels";
import { WardenAPI } from "../api-calls";

const StyledTextContainer = styled.div`
  max-width: 1025px;
  padding-inline: 10px;

  & h1 {
    font-size: 30px;
    font-weight: 600;
    line-height: normal;
    margin-block: 30px;
  }

  & h2 {
    font-size: 22px;
    font-weight: 600;
    line-height: normal;
    margin-block: 30px;
  }

  & p,
  ol,
  ul {
    font-size: 18px;
    font-weight: 400;
    line-height: 140%;
  }

  & .bold {
    font-weight: 600;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 40px 0;
  }
`;

const SubHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  padding-inline: 25px;

  @media (max-width: ${ScreenSizes.narrow}) {
    justify-content: center;
  }
`;

const SubHeaderButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0);
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.2);
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  padding: 10px 20px;
  height: 38px;
  cursor: pointer;

  @media (max-width: ${ScreenSizes.narrow}) {
    font-size: 16px;
    padding: 5px 10px;
  }
`;

const subHeader = () => (
  <SubHeaderContainer>
    <a href="/privacy">
      <SubHeaderButton>Privacy</SubHeaderButton>
    </a>
    <a href="/tos">
      <SubHeaderButton>Terms Of Service</SubHeaderButton>
    </a>
    <a href="mailto:support@starform.co">
      <SubHeaderButton>Support</SubHeaderButton>
    </a>
  </SubHeaderContainer>
);

interface MyState {
  accounts: AccountInfo[] | undefined;
  activeAccountId: string | undefined;
  timeOutNumber: number | undefined;
}

export default class Privacy extends React.Component<{}, MyState> {
  state: MyState = {
    accounts: LSI.getAccounts(),
    activeAccountId: LSI.getActiveAccountId(),
    timeOutNumber: undefined,
  };

  componentDidMount(): void {
    window.addEventListener("focus", () => this.setupTimeoutNumber());
    this.setupTimeoutNumber();
  }

  componentDidUpdate(
    prevProps: Readonly<{}>,
    prevState: Readonly<MyState>
  ): void {
    if (prevState.accounts == undefined && this.state.accounts != undefined) {
      this.setupTimeoutNumber();
    }
  }

  componentWillUnmount(): void {
    if (this.state.timeOutNumber != undefined) {
      window.clearTimeout(this.state.timeOutNumber);
    }
  }

  setupTimeoutNumber(): void {
    if (this.state.timeOutNumber != undefined) {
      window.clearTimeout(this.state.timeOutNumber);
    }
    const timeOutNumber = configureLogoutTimer();
    if (timeOutNumber != undefined) {
      this.setState({ timeOutNumber: timeOutNumber });
    }
  }

  render() {
    const activeAccount = parseActiveAccount(
      this.state.accounts,
      this.state.activeAccountId
    );
    return (
      <PageWrapper
        subHeader={subHeader()}
        activeRoute=""
        fullScreen={false}
        forceLogin={false}
        backgroundImage={backgroundImage}
        accounts={this.state.accounts}
        activeAccount={activeAccount}
        updateAccounts={(accounts: AccountInfo[]): void => {
          this.setState({ accounts: accounts });
        }}
        updateActiveAccount={(account: AccountInfo): void => {
          this.setState({ activeAccountId: account.accountId });
        }}
        removeAccountsAndActive={() =>
          this.setState({ accounts: undefined, activeAccountId: undefined })
        }
        showLogin={undefined}
      >
        <ContentDiv
          smallDevice={isSmallScreen()}
          hasSubHeader={true}
          fullScreen={false}
        >
          <StyledTextContainer>
            <h1>PRIVACY POLICY</h1>
            <h2>Starform Privacy Policy</h2>
            <p>This Privacy Policy describes:</p>
            <ul>
              <li>
                The ways we collect personal data about you and why we do so
              </li>
              <li>How we use your personal data, and</li>
              <li>The choices you have about your personal data.</li>
            </ul>
            <p>
              This Privacy Policy applies to Starform's games, website(s) and
              related services, which we here collectively call the Service. We
              may, from time to time, update this Privacy Policy by posting a
              new version on starform.co. If we make material changes, we will
              notify you by posting within the Service prior to the change
              becoming effective. Your continued use of the Service after the
              effective date will be subject to the new Privacy Policy.
            </p>
            <h2>Contact us</h2>
            <p>
              If you have questions about data protection, or if you have any
              requests regarding issues with your personal data, please contact
              us through in-game customer service. You can also reach us via
              privacy@starform.co, but using our in-game support will provide a
              faster response time from us.
            </p>
            <h2>The data we collect</h2>
            <p className="bold">Data you choose to provide us.</p>
            <ul>
              <li>Contact information (such as name and email address)</li>
              <li>Player username</li>
              <li>Profile information (such as profile photo)</li>
              <li>
                Your messages to the Service (such as chat logs and player
                support tickets)
              </li>
              <li>
                Other data you choose to give us (such as data to identify a
                lost account)
              </li>
            </ul>
            <p className="bold">Data we collect automatically.</p>
            <ul>
              <li>
                Your IP address and mobile device identifiers (such as MAC
                address, IMEI, etc.)
              </li>
              <li>Data describing your account and game progress</li>
              <li>
                General location data Data about your device, such as device
                name and operating system
              </li>
              <li>
                Browser type and language; data we collect with cookies and
                similar technologies
              </li>
              <li>
                Data about your use of the Service, such as gameplay data and
                your interactions with other players inside the Service
              </li>
            </ul>
            <p className="bold">Data we collect from our partners.</p>
            <ul>
              <li>
                Demographic data (to determine the general location of your IP
                address)
              </li>
              <li>
                Data from platforms that distribute the games (to verify
                payment)
              </li>
              <li>
                Data we receive if you link a third party tool with the Service
                (such as Facebook, WeChat or Google)
              </li>
              <li>Data to fight fraud (such as refund abuse in games)</li>
              <li>
                Data for advertising and analytics purposes, so we can provide
                you a better Service
              </li>
            </ul>
            <h2>Why do we collect your data</h2>
            <p className="bold">To make the Service work.</p>
            <ul>
              <li>
                To perform the contract, we process data necessary to Create
                accounts and allow you to play our games and use our Service
              </li>
              <li>Verify and confirm payments</li>
              <li>Send you Service-related communications</li>
              <li>Operate the Service</li>
              <li>Provide and deliver products and services you request</li>
            </ul>
            <p className="bold">
              To make the Service more suitable for our players.
            </p>
            <p>
              To provide a great Service to our players, we have a legitimate
              interest to collect and process necessary data to
            </p>
            <ul>
              <li>
                Update and develop player profiles used within the Service
              </li>
              <li>Develop and improve the Service and player experience</li>
              <li>Customize the way you experience the Service</li>
              <li>Manage our relationship with you</li>
              <li>Provide social features as part of the Service</li>
              <li>
                Respond to your feedback and questions and provide general
                player support
              </li>
              <li>
                Send you Service-related information, such as updates, security
                alerts, and support messages
              </li>
              <li>
                Enable you to communicate with other players using the Service
              </li>
            </ul>
            <p className="bold"> To show personalized advertisements.</p>
            <p>
              To show you personalized advertisements in the Service as well as
              in other websites and services (including email) we have a
              legitimate interest to process necessary data to
            </p>
            <ul>
              <li>
                Track the content you access in connection with the Service and
                your online behavior
              </li>
              <li>
                Deliver, target and improve our advertising and the Service
              </li>
            </ul>
            <p>
              For information on how to opt-out from personalized
              advertisements, see section 'Your rights and options' below.
            </p>
            <p className="bold">To keep the Service safe and fair.</p>
            <p>
              In order to keep the Service safe and fair, to fight fraud and
              ensure acceptable use otherwise, we have a legitimate interest to
              use the necessary data to:
            </p>
            <ul>
              <li>
                Analyze and monitor use of the Service and its social features
              </li>
              <li>Moderate chats either automatically or manually</li>
              <li>Take action against fraudulent or misbehaving players</li>
            </ul>
            <p className="bold">To analyze, profile, and segment.</p>
            <p>
              In all of the above cases and purposes, we may analyze, profile
              and segment all collected data.
            </p>
            <h2>Who can see your data</h2>
            <p>
              Apart from Starform, your data can be accessed by others in the
              following situations:
            </p>
            <h2>Other players and users.</h2>
            <p>
              Social gameplay is a core component of our games. Other players
              may, for example, see your profile data, in-game activities and
              read the messages you have posted.
            </p>
            <h2>Partners working for Starform.</h2>
            <p>
              Starform in some instances, has engaged partners to perform
              services for us. These partners process your data only at and
              according to Starform's instructions to provide the Service, such
              as hosting, player support, advertising, analytics and fraud
              prevention.
            </p>

            <p className="bold">Other companies and public authorities.</p>
            <p>
              In order to combat fraud and illegal activity, we may exchange
              data with other companies and organizations and provide it to
              public authorities in response to lawful requests.
            </p>
            <p>
              We may also disclose your data based on your consent, to comply
              with the law or to protect the rights, property or safety of us,
              our players or others.
            </p>
            <p className="bold">
              Advertising and Social Media partners. The Service includes
              features from partners, such as social media interaction tools and
              in-game advertising.
            </p>
            <h2>International data transfers</h2>
            <p>
              Our Service is global by nature and your data can therefore be
              transferred to anywhere in the world. Because different countries
              may have different data protection laws than your own country, we
              take steps to ensure adequate safeguards are in place to protect
              your data as explained in this Policy.
            </p>
            <h2>Your rights and options</h2>
            <p className="bold">
              Opt-out of marketing emails and other direct marketing.
            </p>
            <p>
              You may opt-out of receiving promotional communications, such as
              marketing emails from us by following the instructions in such
              communications.
            </p>
            <p className="bold">Opt-out of targeted advertising.</p>
            <p>
              You can opt-out of interest-based advertising on mobile
              applications by checking the privacy settings of your Android or
              iOS device and selecting "limit ad tracking" (Apple iOS) or
              "opt-out of interest based ads" (Android).
            </p>
            <p className="bold">Access the personal data we hold about you.</p>
            <p>
              If you request, we will provide you a copy of your personal data
              in an electronic format.
            </p>
            <p className="bold">Your other rights.</p>
            <p>
              You also have the right to correct your data, have your data
              deleted, object to how we use or share your data, and restrict how
              we use or share your data. You can always withdraw your consent,
              for example by turning off GPS location sharing in your mobile
              device settings.
            </p>
            <p>
              We will respond to all requests within a reasonable timeframe.
            </p>
            <h2>Cookies and similar technologies</h2>
            <p>
              Like most online services, we and our partners use cookies and
              similar technologies to provide and personalize the Service,
              analyse use, target advertisements and prevent fraud. You can
              disable cookies in your browser settings, but some parts of the
              Service may then not function properly.
            </p>
            <h2>How do we protect your data</h2>
            <p className="bold">Security Safeguards.</p>
            <p>
              In order to help ensure a secure and safe player experience, we
              are continuously developing and implementing administrative,
              technical and physical security measures to protect your data from
              unauthorized access or against loss, misuse or alteration.
            </p>
            <p className="bold">Data retention.</p>
            <p>
              We retain your data for as long as your account is active or as
              needed to provide you the Service. We will for example
              periodically de-identify unused game accounts and we regularly
              review and de-identify unnecessary data.
            </p>
            <p>
              Note that if you ask us to remove your personal data, we will
              retain your data as necessary for our legitimate business
              interests, such as to comply with our legal obligations, resolve
              disputes, and enforce our agreements.
            </p>
            <h2>Age limits</h2>
            <p>
              We do not collect or solicit personal data about or direct or
              target interest based advertising to anyone under the age of 13 or
              knowingly allow such persons to use our Services. If you are under
              13, please do not share any data about yourself with us, including
              your name, address, telephone number, email address or any other
              personal information. No one under the age of 13 may provide any
              personal data. If we learn that we have collected personal data
              about a child under age 13, we will delete that data as quickly as
              possible. If you believe that we might have any data from or about
              a child under the age of 13, please contact us.
            </p>
          </StyledTextContainer>
        </ContentDiv>
      </PageWrapper>
    );
  }
}
